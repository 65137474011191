import { CheckAuthHandler, SendMessageHandler, StatusChangeHandler } from "../store/stateSchema";

let websocket_url = "ws://localhost:8000";

if (process.env.NODE_ENV === "production") {
  websocket_url = "wss://" + process.env.REACT_APP_HOST;
}

let ws: WebSocket | null = null; // WebSocket reference

const RECONNECT_DELAY = 1000; // Delay in milliseconds before reconnecting

let checkAuthHandler: CheckAuthHandler | null = null;

let sendMessageHandler: SendMessageHandler | null = null;

let statusChangeHandler: StatusChangeHandler | null = null;

const handleStatusChange = (status: boolean) => {
  statusChangeHandler?.(status);
};

const handleOpen = (event: Event): void => {
  console.log("Connection established", event);
  handleStatusChange(true);
};

const handleMessage = (event: MessageEvent): void => {
  console.log('ws message received', event);
  sendMessageHandler?.(event.data);
};

const handleError = (event: Event): void => {
  console.log('Websocket error', event)
  handleStatusChange(false);
};

const handleClose = (event: CloseEvent): void => {
  console.log("Connection closed", event);

  if (!event.wasClean) {
    handleStatusChange(false);

    if (localStorage.getItem('token')) {
      setTimeout(() => {
        console.log("Attempting to reconnect...");
        connectWebSocket();  // Attempt to reconnect
      }, RECONNECT_DELAY);
    }
  }
};

const cleanUpWebSockets = (): void => {
  if (ws) {
    handleStatusChange(false);
    ws.removeEventListener('open', handleOpen);
    ws.removeEventListener('message', handleMessage);
    ws.removeEventListener('error', handleError);
    ws.removeEventListener('close', handleClose);

    if (ws.readyState === WebSocket.OPEN) {
      ws.close();
    }
  }
};

const connectWebSocket = () => {
  // Check if there's an existing WebSocket connection
  if (ws && ws.readyState === WebSocket.OPEN) {
    console.log("WebSocket is already connected");
    return;
  }

  checkAuthHandler?.();

  cleanUpWebSockets();

  ws = new WebSocket(`${websocket_url}/ws/chat/?token=${localStorage.getItem('token')}`);

  ws.addEventListener('open', handleOpen);
  ws.addEventListener('message', handleMessage);
  ws.addEventListener('error', handleError);
  ws.addEventListener('close', handleClose);
};

export const wsAPI = {
  start: (onCheckAuthHandler: CheckAuthHandler, onSendMessageHandler: SendMessageHandler, onStatusChangeHandler: StatusChangeHandler): void => {
    checkAuthHandler = onCheckAuthHandler;
    sendMessageHandler = onSendMessageHandler;
    statusChangeHandler = onStatusChangeHandler;
    connectWebSocket();
  },
  stop: (): void => {
    cleanUpWebSockets();
    checkAuthHandler = null;
    sendMessageHandler = null;
    statusChangeHandler = null;
  },
  sendMessage: (message: any): void => {
    ws?.send(JSON.stringify(message));
  },
};
