import React from 'react';

const MapComponent = ({ maps }: { maps: { embedUrl: string }[] | null }) => {
    if (!maps || maps.length === 0) {
      return null;
    }
  
    return (
      <div style={containerStyle}>
        {maps.map((map, index) => (
          <iframe
            key={index}
            width="100%"
            height="350"
            src={map.embedUrl}
            style={iframeStyle}
            title={`Map ${index + 1}`}
          ></iframe>
        ))}
      </div>
    );
};

const containerStyle: React.CSSProperties = {
  display: 'grid',
  gap: '20px',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
};

const iframeStyle: React.CSSProperties = {
  border: 'none',
};

export default MapComponent;
