import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { StateSchema, ThunkConfig, ChatLog, SessionHistory } from "../stateSchema";
import { BACKEND_URL } from "../store";
import { checkAuthorization, getCurrentUser, getToken, logout } from "./authSlice";
import { addUserMessageCount, setIsPaymentCardVisible } from "./subscriptionSlice";
import { wsAPI } from "../../providers/ws-api";

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    chatLog: [] as ChatLog[],
    currentSessionId: Number(localStorage.getItem('chat_session')) || 0,
    sessionHistory: [] as SessionHistory[],
    sessionsWithBlockedUserPrompt: [] as number[],
    isWebSocketOpen: false,
  },
  reducers: {
    setChatLog: (state, action: PayloadAction<ChatLog[]>) => {
      state.chatLog = action.payload;

      if (!!state.sessionsWithBlockedUserPrompt.find(id => id === state.currentSessionId)) {
        state.chatLog.push(
          { role: 'assistant', message: 'Running...', timestamp: new Date().toUTCString(), chat_session: state.currentSessionId, images: [], id: 0 }
        );
      }
    },
    addUserMessageToChatLog: (state, action: PayloadAction<string>) => {
      const newChatLogEntry: ChatLog[] = [
        { role: 'user', message: action.payload, timestamp: new Date().toUTCString(), chat_session: state.currentSessionId, images: [], id: 0 },
        { role: 'assistant', message: 'Running...', timestamp: new Date().toUTCString(), chat_session: state.currentSessionId, images: [], id: 0 },
      ];

      state.chatLog.push(...newChatLogEntry);

      const historyIdx = state.sessionHistory.findIndex(sh => sh.id === state.currentSessionId);

      if (historyIdx !== -1 && !state.sessionHistory[historyIdx].first_message) {
        state.sessionHistory[historyIdx].first_message = action.payload;
      }

      state.sessionsWithBlockedUserPrompt.push(state.currentSessionId);
    },
    addWSMessageToChatLog: (state, action: PayloadAction<ChatLog>) => {
      if (state.currentSessionId === action.payload.chat_session) {
        const isDuplicate = state.chatLog.some(entry => entry.id === action.payload.id);

        if (!isDuplicate) {
          state.chatLog = [
            ...state.chatLog.filter(entry => entry.message !== "Running..."),
            action.payload
          ]
        }
      } else {
        // add badge "new message" for corresponding session
      }

      state.sessionsWithBlockedUserPrompt = state.sessionsWithBlockedUserPrompt.filter(id => id !== action.payload.chat_session);
    },
    setCurrentSessionId: (state, action: PayloadAction<number>) => {
      state.currentSessionId = action.payload;
      localStorage.setItem('chat_session', action.payload.toString());
    },
    setSessionHistory: (state, action: PayloadAction<SessionHistory[]>) => {
      state.sessionHistory = action.payload;
    },
    addChatSession: (state, action: PayloadAction<SessionHistory>) => {
      state.sessionHistory.unshift(action.payload);
    },
    removeChatSession: (state, action: PayloadAction<number>) => {
      state.sessionHistory = state.sessionHistory.filter(sh => sh.id !== action.payload);
    },
    resetSessionsWithBlockedUserPrompt: (state) => {
      state.sessionsWithBlockedUserPrompt = [];
    },
    setIsWebSocketOpen: (state, action: PayloadAction<boolean>) => {
      state.isWebSocketOpen = action.payload;
    },
  },
})

export const {
  setChatLog,
  addUserMessageToChatLog,
  addWSMessageToChatLog,
  setCurrentSessionId,
  setSessionHistory,
  addChatSession,
  removeChatSession,
  resetSessionsWithBlockedUserPrompt,
  setIsWebSocketOpen,
} = chatSlice.actions;

export const chatReducer = chatSlice.reducer;

export const getChatLog = (state: StateSchema) => state.chat.chatLog;

export const getCurrentSessionId = (state: StateSchema) => state.chat.currentSessionId;

export const getSessionHistory = (state: StateSchema) => state.chat.sessionHistory;

export const getPromptInputIsBlocked = (state: StateSchema) => !!state.chat.sessionsWithBlockedUserPrompt.find(id => id === state.chat.currentSessionId);

export const getIsWebSocketOpen = (state: StateSchema) => state.chat.isWebSocketOpen;

export const fetchChatLog = createAsyncThunk<unknown, void, ThunkConfig>('chat/fetchChatLog', async (_, { getState, dispatch }) => {
  try {
    const sessionId = getCurrentSessionId(getState());

    if (sessionId) {
      const response = await axios.get<{ messages: ChatLog[] }>(`${BACKEND_URL}/chat/chat-history/${sessionId}/`, {
        headers: {
          Authorization: `Bearer ${getToken(getState())}`,
        },
      });

      if (!response.data) {
        throw Error();
      }

      dispatch(setChatLog(response.data.messages.map((message) => ({
        ...message,
        message: message.message.replace(/\n{2,}/g, '\n') // Replace double line breaks with single
      }))));
    }
  } catch (error: unknown) {
    // setErr(true);
    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
});

export const fetchChatSessionHistory = createAsyncThunk<unknown, void, ThunkConfig>('chat/fetchChatSessionHistory', async (_, { getState, dispatch }) => {
  try {
    const response = await axios.get<SessionHistory[]>(`${BACKEND_URL}/chat/sessions-history/`, {
      headers: {
        Authorization: `Bearer ${getToken(getState())}`,
      },
    });
    if (response.data.length > 0) {
      dispatch(setSessionHistory(response.data));

      if (!response.data.find(sh => sh.id === getCurrentSessionId(getState()))) {
        dispatch(setCurrentSessionId(getSessionHistory(getState())[0].id))
      } else if (getCurrentSessionId(getState()) === 0 && getSessionHistory(getState()).length > 0) {
        dispatch(setCurrentSessionId(getSessionHistory(getState())[0].id))
      }
    } else {
      dispatch(createChatSession());
    }
  } catch (error: unknown) {
    // setErr(true);
    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
});

export const createChatSession = createAsyncThunk<unknown, void, ThunkConfig>('chat/createChatSession', async (_, { getState, dispatch }) => {
  try {
    const response = await axios.post(`${BACKEND_URL}/chat/session/create/`, {}, {
      headers: {
        Authorization: `Bearer ${getToken(getState())}`,
      },
    });

    dispatch(addChatSession(response.data));
    dispatch(setCurrentSessionId(response.data.id));
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
});

export const deleteChatSession = createAsyncThunk<unknown, void, ThunkConfig>('chat/deleteChatSession', async (_, { getState, dispatch }) => {
  try {
    const sessionId = getCurrentSessionId(getState());

    if (sessionId) {
      await axios.delete(`${BACKEND_URL}/chat/chat-history/${sessionId}/`, {
        headers: {
          Authorization: `Bearer ${getToken(getState())}`,
        },
      });
      dispatch(setChatLog([]));
      dispatch(removeChatSession(sessionId));

      const sessionHistory = getSessionHistory(getState());

      if (sessionHistory.length > 0) {
        dispatch(setCurrentSessionId(sessionHistory[0].id));
      } else {
        dispatch(createChatSession());
      }
    }
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
      dispatch(logout());
    }
  }
});

export const connectWebSocket = createAsyncThunk<unknown, void, ThunkConfig>('chat/connectWebSocket', async (_, {dispatch}) => {
  const handleCheckAuth = (): void => {
    dispatch(checkAuthorization());
  };
  
  const handleSendMessage = (message: string): void => {
    const data = JSON.parse(message);

    const chatLogEntry: ChatLog = {
        role: "assistant",
        message: data?.response?.replace(/\n{2,}/g, '\n'),
        timestamp: new Date().toUTCString(),
        chat_session: data?.session_id,
        images: data?.images,
        id: data?.last_message
    };

    dispatch(addWSMessageToChatLog(chatLogEntry));
    dispatch(addUserMessageCount(1));

    if (data?.response?.includes("exceeded your free limit")) {
        dispatch(setIsPaymentCardVisible(true));
    }
  };

  const handleStatusChange = (status: boolean): void => {
    dispatch(resetSessionsWithBlockedUserPrompt());
    dispatch(setIsWebSocketOpen(status));
  };

  wsAPI.start(handleCheckAuth, handleSendMessage, handleStatusChange);
});

export const closeWebSocket = createAsyncThunk<unknown, void, ThunkConfig>('chat/closeWebSocket', async () => {
  wsAPI.stop();
});

export const sendMessage = createAsyncThunk<unknown, string, ThunkConfig>('chat/sendMessage', async (message, {getState, dispatch}) => {
  dispatch(addUserMessageToChatLog(message));

  const userMessage = { message, user_id: getCurrentUser(getState()), session_id: getCurrentSessionId(getState()) };

  wsAPI.sendMessage(userMessage);
});
